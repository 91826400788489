* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

.link {
  color: #34c759;
}

img {
  vertical-align: top;
}

* html,
body {
  width: 100%;
  height: 100%;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;800&display=swap');

.container {
  box-sizing: border-box;
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px;
}
