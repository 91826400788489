@import 'styles/helpers/helpers';

body {
  background-color: $theme_background_color;
  color: $main_text_color;
  @include globalRegularFont();
}

.lock {
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper-content {
  flex: 1 1 100%;
}

.content {
  padding-top: 132px;

  @media (max-width: 900px) {
    padding-top: 50px;
  }
}

.title {
  font-weight: 600;

  &__main {
    font-size: 18px;
    line-height: 24px;
  }
}

.appButton {
  display: flex;
  width: 190px;
  height: 57px;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 900px) {
    width: 150px;
  }

  &.-a {
    background-image: url(/images/app-store.png);
    margin-bottom: 12px;
  }

  &.-g {
    background-image: url(/images/google-play.png);
  }

  &.-footer {
    width: 134px;
    height: 40px;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.buttonAdd {
  margin-top: 48px;
  @include globalSemiBoldFont();
  font-size: 12px;
  line-height: 24px;
  color: $green_theme_color;
  display: flex;
  align-items: center;
  align-self: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  &::after {
    content: url(/images/icons/button-add-arrow-down.svg);
    margin-left: 14px;
  }
}

#privacyCheckbox {
  display: none;

  &:checked + label {
    background-image: url(/images/icons/feedback-check.svg);
    background-position: center;
    padding: 0;
    border: none;
  }
}

.img {
  max-width: 100%;
}

.reviews {
  .swiper-container {
    padding-bottom: 32px;
  }

  .swiper-container-horizontal > .swiper-pagination-progressbar {
    background: $additional_text_color;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 100px;
    overflow: hidden;

    .swiper-pagination-progressbar-fill {
      background: $green_theme_color;
    }
  }
}

@media ($_tablet_up) {
  .title {
    &__main {
      font-size: 24px;
      line-height: 1.3;
    }
  }
}

@media ($_laptop_up) {
  .title {
    &__main {
      font-size: 30px;
    }
  }
}

@media ($_desktop_up) {
  .title {
    &__main {
      font-size: 34px;
    }
  }
}
